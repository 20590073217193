:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}

/*Portfolio website section*/

.portfolio {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.portfolio__header {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  margin: 2rem 0rem;
  width: 100vw;
}

.portfolio__content {
  grid-template-columns: repeat(1fr, auto);
  grid-column: start/end;
  grid-gap: 2rem;
  display: grid;
  width: 100vw;
  padding: 2rem;
  background-color: black;
}

/* Card for portfolio section*/
.card {
  transition: 0.2s transform ease-out;
}
.card__content {
  padding: 1rem;
}

.card__content-img {
}

.card:hover {
  transform: scale(1.05, 1.05);
}

.card__content-img img {
  max-width: 100%;
}

.card__content-info {
  display: flex;
}
.card__content-info {
}

.info-1 {
  flex: 2;
}
.info-1 p {
  color: #bebebe;
  margin-top: 0.5rem;
}

.info-2 {
  flex: 1;
  margin-left: 2rem;
}

.info-2 img {
  margin-top: 0.5rem;
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}
.info-2 img:nth-of-type(n + 2) {
  margin-left: 1rem;
}

/* About section website */

.about {
}

.about__left-container {
}
.about__right-container {
}

.about__picture {
}

.about__info {
}

/*Media Queries*/

/*Phone*/
@media screen and (min-width: 1000px) {
  .portfolio__content {
    grid-template-columns: repeat(2, auto);
  }
}

/* Laptop*/
@media screen and (min-width: 1200px) {
}
/* Desktop */

@media screen and (min-width: 1800px) {
  .portfolio__content {
    grid-template-columns: repeat(3, auto);
  }
}
